<template>
    <div>
        <a-spin :spinning="loading">
            <a-form ref="form" name="form" :model="formState" @finish="onSearch">
                <a-row>
                    <a-form-item class="ui-form__item" name="organizationId" label="影院组织">
                        <a-select placeholder="请选择" v-model:value="formState.organizationId" style="width: 180px;"
                                  @change="getAllCinemaList">
                            <!-- <a-select-option :value="''">全部</a-select-option> -->
                            <a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id">
                                {{ item.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item class="ui-form__item" name="cinemaId" label="所属影院商城">
                        <a-select placeholder="请选择" v-model:value="formState.cinemaId" style="width: 180px;">
                            <a-select-option :value="''">全部</a-select-option>
                            <a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id">
                                {{ item.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>

					<a-form-item label="交易时间" class="ui-form__item">
                        <a-range-picker v-model:value="time" :disabledDate="disabledDate" @calendarChange="onCalendarChange"></a-range-picker>
                    </a-form-item>
                </a-row>

                <a-row v-show="showAll">
                    <a-form-item label="会员昵称" name="nickName" class="ui-form__item">
                        <a-input v-model:value="formState.nickName" placeholder="请输入"></a-input>
                    </a-form-item>

                    <a-form-item label="会员手机" name="phone" class="ui-form__item">
                        <a-input v-model:value="formState.phone" placeholder="请输入"></a-input>
                    </a-form-item>

					<a-form-item class="ui-form__item" label="订单来源" name="source">
						<a-select v-model:value="formState.source" style="width: 190px;" placeholder="请选择订单来源">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option :value="1">微信小程序</a-select-option>
							<a-select-option :value="2">抖音小程序</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="核销状态" name="orderStatus" class="ui-form__item">
                        <a-select v-model:value="formState.orderStatus" placeholder="请选择">
                            <a-select-option :value="0">全部</a-select-option>
                            <a-select-option :value="4">已退货</a-select-option>
                            <a-select-option :value="5">未核销</a-select-option>
                            <a-select-option :value="6">已核销</a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item label="卖品名称" name="goodsTitle" class="ui-form__item">
                        <a-input v-model:value="formState.goodsTitle" placeholder="请输入"></a-input>
                    </a-form-item>

                    <a-form-item label="商户流水号" name="outTradeNo" class="ui-form__item">
                        <a-input v-model:value="formState.outTradeNo" placeholder="请输入"></a-input>
                    </a-form-item>

                    <a-form-item label="订单编号" name="orderNo" class="ui-form__item">
                        <a-input v-model:value="formState.orderNo" placeholder="请输入"></a-input>
                    </a-form-item>

					<a-form-item label="支付类型" name="payType" class="ui-form__item">
						<a-select v-model:value="formState.payType" style="width: 190px;" placeholder="请选座支付类型">
							<a-select-option :value="''">全部</a-select-option>
							<a-select-option :value="1">现金支付</a-select-option>
							<a-select-option :value="2">卖品券支付</a-select-option>
							<a-select-option :value="3">混合支付</a-select-option>
							<a-select-option :value="4">后台核销</a-select-option>
							<a-select-option :value="6">线上会员卡支付</a-select-option>
							<a-select-option :value="8">影城会员卡支付</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" label="平台发起提现" name="isSubLedger">
						<a-select style="width: 190px;" placeholder="选择取票状态" v-model:value="formState.isSubLedger">
							<a-select-option :value="''">全部</a-select-option>
							<a-select-option :value="1">已发起</a-select-option>
							<a-select-option :value="0">未发起</a-select-option>
						</a-select>
					</a-form-item>
                </a-row>

                <a-row>
                    <a-col :span="18">
						<span v-permission="['snack_order_list_export']">
							<exportComponent type="snackOrder" :searchData="searchData"></exportComponent>
						</span>
                        <!-- <a-button v-permission="['mall_order_list_export']" type="primary" ghost @click="title = '';exportVisible = true;">导出</a-button> -->
                        <a style="margin-left: 20px;" @click="showAll = !showAll">
                            {{ showAll ? '收起' :'展开' }}
                            <Icon v-show="!showAll" icon="DownOutlined"></Icon>
                            <Icon v-show="showAll" icon="UpOutlined"></Icon>
                        </a>

                    </a-col>
                    <a-col :span="6" style="text-align: right;">
                        <a-button type="primary" html-type="submit">搜索</a-button>
                        <a-button style="margin-left: 20px;" @click="reset">重置</a-button>
                    </a-col>
                </a-row>
            </a-form>
            <a-table style="margin-top: 10px;" :columns="columns" :dataSource="list" rowKey="orderId"
                     :pagination="pagination" :scroll="{ x: 2300 }">
                <template #bodyCell="{ column, record, index }">
                    <template v-if="column.key === 'store'">
                        {{ record.orderStoreVO.name }}
                    </template>
					
					<template v-if="column.key === 'source'">
						<div>
							<a-tag color="green" v-if="record.source === 1">
								微信小程序
							</a-tag>
							<a-tag color="blue" v-else> 抖音小程序 </a-tag>
						</div>
					</template>
					
                    <template v-if="column.key === 'tradeInfo'">
                        <div>订单编号：<span>{{ record.orderNo }}</span></div>
                        <div>支付状态：<span style="color: red;">{{ transStatus(record.status, record.deliveryType) }}</span></div>
                       <div>支付方式：
							<a-tag v-if="record.payMessageType === 'wx_lite'" color="green">微信支付</a-tag>
							<a-tag v-else-if="record.payMessageType === 'dypay'" color="green">抖音支付</a-tag>
							<a-tag v-else-if="record.payMessageType === 'dy_alipay'" color="green">抖音支付宝支付</a-tag>
							<a-tag v-else-if="record.payMessageType === 'dy_wxpay'" color="green">抖音微信支付</a-tag>
							<a-tag v-else-if="record.payMessageType === 'alipay'" color="blue">支付宝支付</a-tag>
							<a-tag v-else-if="record.payMessageType === 'verify'" color="cyan">后台核销</a-tag>
              <a-tag v-else-if="record.payType == 6" color="blue">线上会员卡</a-tag>

							<span v-else>--</span>
						</div>
                        <div>交易总额：<span style="color: orange;">￥{{ record.payType == 6?record.balanceDeduction:record.payment }}</span></div>
                        <div>下单时间：<span>{{ transDateTime(record.createTime) }}</span></div>
                    </template>

					<template v-if="column.key === 'payType'">
						<a-tag style="margin-bottom: 8px;" :color="['#87d068', '#f50', '#108ee9', '#2db7f5', '', 'blue', '', '#999'][record.payType - 1]">{{ ['现金支付', '卖品券', '混合支付', '后台核销', '', '线上会员卡', '', '影城会员卡'][record.payType - 1] }}</a-tag>
					</template>

                    <template v-else-if="column.key === 'userInfo'">
                        <div>会员昵称：{{ record.nickname || '--' }}</div>
                        <div>会员手机：{{ record.phone || '-' }}</div>
                    </template>

                    <template v-else-if="column.key === 'goodsInfo'">
                        <div class="ui-goods" v-for="item in record.goodsInfoVOS" :key="item.id">
							<div style="display: flex;align-items: flex-start;">
								<a-image style="width: 50px;border-radius: 4px;" :src="item.imgUrl.split(',')[0]"></a-image>
								<div style="margin-left: 8px;">
									<div style="font-size: 12px;">
										<a-tag v-if="item.isCard" style="padding: 2px;line-height: 12px;" color="cyan" size="small">次卡</a-tag>
										{{ item.mainTitle }}
									</div>
									<div style="margin-top: 2px;font-size: 14px;color: orange;">￥{{ item.nowPrice }}
                                        <span style="margin-left: 10px;color:#999;">x{{ item.buyNum }}</span>
                                    </div>
								</div>
							</div>
							<div v-if="item.isCard" style="margin-top: 4px;font-size: 12px;">
								<div>卡等级: {{ item?.cardVolumeVO?.title }}</div>
								<div v-if="item.cardNumberList">卡号: 
									<span v-for="(subItem, subIndex) in item.cardNumberList">
										{{ subItem }}
										<span v-if="subIndex < item.cardNumberList.length - 1">、</span>
									</span>
								</div>
								<div>观影次数: {{ item.cardBalanceTimes }}</div>
							</div>
                            <div v-if="item.isCoupon" style="margin-top: 4px;font-size: 12px;">
                                <div style="margin-bottom: 5px;" v-if="item.ticketBatchNum">
                                    <div>兑换券批次号: {{ item.ticketCouponList.length ? item.ticketCouponList[0].batchNo : '' }}</div>
                                    <div>兑换券数量: {{ item.ticketBatchNum }}张 x{{ item.buyNum }}</div>
                                    <div>
                                        兑换券号：
                                        <a-tag v-for="(item, i) in item.ticketCouponList" :key="i">
                                            {{ item.barcode }}
                                        </a-tag>
                                    </div>
                                </div>
                                <div v-if="item.snackBatchNum">
                                    <a-divider style="margin: 6px 0;" v-if="item.ticketBatchNum"/>
                                    <div>卖品券批次号: {{ item.snackCouponList.length ? item.snackCouponList[0].batchNo : '' }}</div>
                                    <div>卖品券数量: {{ item.snackBatchNum }}张 x{{ item.buyNum }}</div>
                                    <div>卖品券号:
                                        <a-tag v-for="(item, i) in item.snackCouponList" :key="i">
                                            {{ item.barcode }}
                                        </a-tag>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-else-if="column.key === 'cardInfo'">
                        <div v-if="record.goodsInfoVOS[0].isCard">
                            <a-tag color="orange" style="color: #fa8c16;">
                                {{ ['次卡', '年卡'][record.goodsInfoVOS[0].cardType - 1] }}
                            </a-tag>
                            <div>卡号：{{ record.cardNumber }}</div>

                            <div v-if="record.goodsInfoVOS[0].cardType === 1">
                                开卡次数：{{ record.goodsInfoVOS[0].cardBalanceTimes }}</div>
                            <!-- <div>赠送次数：{{ record.cardGiftTimes }}</div> -->
                        </div>
                        <div v-else>--</div>
                    </template>

                    <template v-else-if="column.key === 'deliveryInfo'">
                        <div v-if="record.deliveryType === 2">
                            <div>配送方式：<a-tag color="cyan">自提</a-tag></div>
                            <div :id="'copy' + index">{{ record.verificationCode }}
                            </div>
                            <a v-if="record.verificationCode"
                               @click="onCopy(index)">复制核销码</a>
                        </div>
						<div v-else>--</div>
                    </template>

                    <template v-else-if="column.key === 'statusInfo'">
                        <div v-if="record.deliveryType == 2">
                            核销状态：<span
                                style="color: #fd6846;">{{ transStatus(record.status, record.deliveryType) }}</span>
                            <div v-if="record.verificationUserName">
                                <div>核销人员：{{ record.verificationUserName }}</div>
                                <div>核销时间：{{ transDateTime(record.finishTime) }}</div>
                            </div>
                        </div>
						<div v-else>--</div>
                    </template>

                    <template v-else-if="column.key === 'receiptInfo'">
                        <span v-if="record.deliveryType !== 3">--</span>
                        <div v-else>
                            <div>{{ record.orderAddress.name }}</div>
                            <div>{{ record.orderAddress.phone }}</div>
                            <div>{{ record.orderAddress.showAddress }}</div>
                        </div>
                    </template>

                    <template v-else-if="column.key === 'createTime'">
                        {{ transDateTime(record.createTime) }}
                    </template>

                    <template v-else-if="column.key === 'refund'">
                        <div v-if="record.status === 'AFTERSALE_FINISH'">
                            <div style="color: red;">已退款</div>
                            <div>退款{{ record.orderAfterSale ? record.orderAfterSale.refund : '' }}元<span
                                    v-if="record.refundRemark">，{{ record.refundRemark || '' }} </span></div>
                        </div>
                        <div v-else>--</div>
                    </template>
					
					<template v-else-if="column.key === 'isSubLedger'">
						{{ record.isSubLedger ? '已发起' :'未发起' }}
					</template>
					
                    <template v-else-if="column.key === 'action'">
                        <a-button type="link" v-permission="['snack_order_list_refund']"
							v-if="['WAIT_SELLER_SEND_GOODS', 'WAIT_CHECK', 'TRADE_FINISH', 'WAIT_BUYER_CONFIRM_GOODS', 'WAIT_COMMENT'].indexOf(record.status) !== -1"
							@click="onRefund(record)">售后退款</a-button>
                        <div v-if="record.deliveryType === 2">
                            <a v-permission="['snack_order_list_verification']" v-if="record.status === 'WAIT_CHECK'"
                               @click="onVerification(record)">确认核销</a>
                            <a v-else-if="record.status === 'TRADE_FINISH'" disabled style="color: #CCC;">已核销</a>
                            <span v-else>--</span>
                        </div>
                        <div v-else>--</div>
                    </template>
                </template>
            </a-table>

            <a-modal v-model:visible="refundVisible" title="退款" @cancel="onRefundCancel" @ok="onRefundOk">
                <a-spin :spinning="loading">
                    <a-form ref="refundForm" name="refundForm" :model="refundData">
                        <a-form-item label="退款金额">
							{{ refundData.refund }}
                            <!-- <a-input-number v-model:value="refundData.refund" :min="0" placeholder="请输入"></a-input-number> -->
                        </a-form-item>

                        <a-form-item label="退款备注" name="remark" :rules="[{required: true, message: '请输入退款的备注和金额'}]">
                            <a-textarea v-model:value="refundData.remark" placeholder="请输入退款的备注和金额"></a-textarea>
                        </a-form-item>
                    </a-form>
                </a-spin>
            </a-modal>

        </a-spin>
    </div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import exportComponent from '@/components/exportReport/exportReport.vue';
	import {
		getOrganizationList,
		getCinemaList
	} from '@/service/modules/cinema.js';
	import {
		getOrderBaseList,
		verificationOrderBase,
		snackOrderAfterSale
	} from '@/service/modules/mall.js';
	
	export default {
		components: {
			Icon,
			exportComponent
		},
		data() {
			return {
				loading: false,
				showAll: false,
				organizationList: [],
				cinemaAllList: [],
				time: [],
				formState: {
					saleType: 2,
					//organizationId: '',
					cinemaId: '',
					payType: '',
					isSubLedger: ''
				},
				searchData: {},
				list: [],
				columns: [{
					title: '商城名称',
					key: 'store'
				}, {
					title: '订单来源',
					key: 'source',
					width: 120,
					align: 'center'
				}, {
					title: '支付信息',
					key: 'tradeInfo',
					width: 300
				}, {
					title: '支付类型',
					key: 'payType',
					width: 100
				}, {
					title: '商户流水号',
					dataIndex: 'outTradeNo',
					width: 250
				}, {
					title: '会员信息',
					key: 'userInfo',
					width: 220
				}, {
					title: '卖品信息',
					key: 'goodsInfo'
				}, {
					title: '取货信息',
					key: 'deliveryInfo',
					align: 'center',
					width: 160
				}, {
					title: '核销信息',
					key: 'statusInfo',
					width: 260
				}, {
					title: '售后退款备注',
					key: 'refund',
					width: 180
				}, {
					title: '平台发起提现',
					key: 'isSubLedger',
					width: 120
				}, {
					title: '操作',
					key: 'action',
					width: 130,
					align: 'center',
					fixed: 'right'
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				importVisible: false,
				modelRef: {
					fileList: [],
				},
				sendModelRef: {},
				sendOrderItem: {},
				refundVisible: false,
				refundData: {},
				tracesVisible: false,
				traces: {},
			}
		},
		created() {
		    this.time = [this.moment().startOf('day'), this.moment().endOf('day')];
			this.getOrganizationList();
			this.onSearch();
		},
		methods: {
			transStatus(val, type) {
				if (val === 'WAIT_BUYER_PAY') {
					return '待支付';
				}
				if (val === 'SYSTEM_CANCEL') {
					return '系统超时取消';
				}
				if (val === 'CUSTOMER_CANCEL') {
					return '用户自行取消';
				}
				if (val === 'WAIT_SELLER_SEND_GOODS') {
					return '待发货'
				}
				if (val === 'WAIT_CHECK') {
					return '待核销'
				}
				if (val === 'TRADE_FINISH') {
					if (type === 2) {
						return '已核销'
					} else {
						return '订单完成'
					}
				}
				if (val === 'WAIT_BUYER_CONFIRM_GOODS') {
					return '待收货'
				}
				if (val === 'AFTERSALE_FINISH') {
					return '已退款'
				}
				if (val === 'WAIT_COMMENT') {
					return '已签收'
				}
			},
			onSearch() {
				this.pagination.current = 1;
				//this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.searchData.organizationId = this.searchData.organizationId ? this.searchData.organizationId :
					undefined;
				this.searchData.cinemaId = this.searchData.cinemaId ? this.searchData.cinemaId : undefined;
				this.searchData.source = this.searchData.source ? this.searchData.source : undefined;
				// this.searchData.deliveryType = this.searchData.deliveryType ? this.searchData.deliveryType : undefined;
				this.searchData.orderStatus = this.searchData.orderStatus ? this.searchData.orderStatus : undefined;
				// this.searchData.payStatus = this.searchData.payStatus ? this.searchData.payStatus : undefined;
				this.searchData.isSubLedger = this.searchData.isSubLedger ? this.searchData.isSubLedger : undefined;
				if (this.time && this.time.length) {
					this.searchData.startTime = this.time[0].startOf('day').valueOf() / 1000;
					this.searchData.endTime = parseInt(this.time[1].endOf('day').valueOf() / 1000);
				}
				this.getData();
			},
			reset() {
				this.time = [this.moment().startOf('day'), this.moment().endOf('day')];
				this.$refs.form.resetFields();
				if (this.organizationList.length) {
					this.formState.organizationId = this.organizationList[0].id;
					this.getAllCinemaList(this.formState.organizationId);
				}
				this.onSearch();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getOrderBaseList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.pagination.total = ret.data.totalCount;
						this.list = ret.data.list;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			async getOrganizationList() {
				this.loading = true;
				try {
					let ret = await getOrganizationList({
						page: 1,
						pageSize: 999999
					});
					if (ret.code === 200) {
						this.organizationList = ret.data.list;
						if (this.organizationList.length) {
							this.formState.organizationId = this.organizationList[0].id;
							this.getAllCinemaList(this.formState.organizationId);
						}
						this.onSearch();
					} else {
						this.loading = false;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				this.formState.cinemaId = '';
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
			onVerification(item) {
				this.$confirm({
					title: '提示',
					content: '确定核销完成吗？',
					onOk: async () => {
						this.loading = true;
						try {
							let ret = await verificationOrderBase({
								orderId: item.orderId
							});
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('核销成功');
								this.getData();
							}
						} catch (e) {
							this.loading = false;
						}
					}
				})
			},
			disabledDate(current) {
				if (!this.time || this.time.length === 0) {
				    return current && current > this.moment().endOf('day');
				}
			 	const tooLate = this.time[0] && current.diff(this.time[0], 'days') > 31;
				const tooEarly = this.time[1] && this.time[1].diff(current, 'days') > 31;
				const tooCurrent = current && current > this.moment().endOf('day');
			    return tooEarly || tooLate || tooCurrent;
			},
			onCalendarChange(value) {
				this.time = value || [];
			},
			onRefund(item) {
				this.refundData = {
					id: item.orderId,
					refund: item.payment
				};
				this.refundVisible = true;
			},
			onRefundCancel() {
				this.refundData = {};
				this.refundVisible = false;
			},
			onRefundOk() {
				this.$refs.refundForm.validateFields().then(async () => {
					this.loading = true;
					try {
						let ret = await snackOrderAfterSale({
							orderId: this.refundData.id,
							refund: this.refundData.refund.toFixed(2) ,
							description: this.refundData.remark
						});
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('退款成功');
							this.onRefundCancel();
							this.getData();
						}
					} catch (e) {
						this.loading = false;
					}
				})
			},
			onCopy(index) {
				const range = document.createRange();
				range.selectNode(document.querySelector("#copy" + index));
				const selection = window.getSelection();
				if (selection.rangeCount > 0) selection.removeAllRanges();
				selection.addRange(range);
				document.execCommand('Copy');
				this.$message.success('复制成功');
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
	.ui-goods {
		padding: 6px 10px;
		border-radius: 10px;
		border: solid 1px #EEE;
	}
	.ui-goods + .ui-goods {
		margin-top: 4px;
	}
</style>
